.notFoundContainer {
    display: flex;
    flex-flow: column nowrap;
    background: aliceblue;
    margin: 80px 0 0 0;
    height: calc(100vh - 80px);
    justify-content: center;
}

.notFoundContent {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}
