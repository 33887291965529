.ittvContainer {
    display: flex;
    flex-flow: column nowrap; 
}

.bannerIttvContainer {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    flex: 1;
    background: white;

}

.videoPromoIttv {
    display: flex;
    max-width: 100%;
}

#planosIttvContainer {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.planoIttvCard {
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center;
    right: 10px;
    color: white;
    width: 50%;
    height: 100%;
}

.planoIttvCardContent {
    display: flex;
    flex-flow: row wrap;
    justify-content: end;
}

.planoIttvButton {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

#ittvPlano{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 20px;
    height: 100px;
    width: 100px;
}

.bannerIttvimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ittvPlanoTitle {
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    margin: 10px;
}
.ittvPlanoSubtitle {
    font-size: 0.8em;
    text-align: center;
    margin: 10px;
}

.imgIttvPlano {
    width: 30%;
    height: 50%;
    object-fit: scale-down;
    margin: 3px 6px;
}

.ittvPlanosContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-flow: row nowrap; 
    padding: 10px;
    background: white;
    width: 100%;
}

.ittvPlanosContainerMobile {
    display: flex;
    align-items: center;
    flex-flow: column nowrap; 
    background: white;
}
.ittvPlanoimg {
    min-width: 200px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 10px;

}

.ittvPlanoimgMobile {
    max-width: 300px;
}

/*
.ittvPlanoimg:hover {
    filter: brightness(90%);
    transition: all 200ms ease-in;
}

.ittvPlanoimg:not(:hover) {
    filter: brightness(100%);
    transition: all 200ms ease-out;
}
*/

.ittvPlanoTextContainer {
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-content: center;
    width: 100%;
    color: white;
}


.ittvCardContainer {
    display: flex;
    background: transparent;
    perspective: 3000px;
    width: 300px;
    height: 300px;
    margin: 0 10px;
}

.ittvPlano {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.ittvCardContainer:hover .ittvPlano {
    transform: rotateX(180deg);
}

.ittvPlanoFrente, .ittvPlanoVerso{
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
  
.ittvPlanoVerso {
    transform: rotateX(180deg);
}

.filmesContainer {
    display: flex;
    justify-content: center;
    margin: 10px;
}

.filmesCarousel {
    margin: 10px ;
}

.tituloFilmesContainer {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: black;
    text-align: center;
    width: 100%;
}

.tituloFilmes {
    color: white;
    text-align: center;
    border-radius: 15%;
}


.textoFilmes {
    color: white;
    text-align: center;
    border-radius: 15%;
}


.imgCanais {
    display: flex;
    object-fit: cover;
    width: 100%;
}

.planosTVContainer {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    background: white;
}

#carouselFilmsContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 99%;
    height: 420px;
    z-index: 0;
}
.imgFilmesCarousel {
    flex:1;
    height: 400px;
    object-fit: scale-down;
    user-select: none;
}

#carouselFilmsContainer>.swiper-wrapper {
    transition-timing-function: linear; 
}




@media screen and (max-width: 768px) {
    #carouselFilmsContainer {
        height: 300px;
    }
    .imgFilmesCarousel {
        height: 280px;
    }
    .bannerIttvimg {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .planoIttvCard {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        align-items: center;
        color: white;
        width: 100%;
        height: 30%;
        gap: 25% 0;
        left: 0;
        bottom: 10%;

    }
    .planoIttvCardContent {
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

    }

    .imgIttvPlano {
        width: 30%;
        height: 60%;

    }
    .planoIttvButton {
        display: flex;
        justify-content: space-around;
        width: 100%;
    }
}

@media screen and (max-width: ) {
    
}