.cadastroContainer {
    display: flex;
    flex-flow: column nowrap;
}

.formCadastro {
    display: flex;
    flex-flow: column nowrap;
    flex: 2;
    background-color: azure;
    margin: 70px 0 0 0;
    padding: 20px 0;

}

.inputFormCadastro {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 20px 0 0 0 ;
}

.enderecoCadastro {
    display: flex;
    flex-flow: row nowrap;
}

.cardCadastro {
    justify-content: center;
    display: flex;
    flex: 1;
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
}

@media only screen and (max-width: 760px) {
    .formCadastro {
        flex-flow: column-reverse nowrap;
    }
}