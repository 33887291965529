.footerContainer {
    display: flex;
    flex-direction: column;
    background-color: #333;
    color: white;

}

.footerContainerButtons {
    display: flex;
    flex-flow: row nowrap;
    padding: 20px 0;
    justify-content: space-around;

}

.footerButton {
    padding: 10px;
    margin: 0 20%;
    cursor: pointer;
    border-radius: 4px;
    color: white;
    text-align: center;
}

.footerButton:hover {
    background-color: #fff1;
    transition: all 100ms ease-out;
}
.footerButton:not(:hover) {
    background-color: #0000;
    transition: all 100ms ease-in;
}
.footerTitle {
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    justify-content: center;
    text-align: center;
}

.footerMenu, .footerServicos, .footerContato {
    flex: 1;
    padding: 0 20px;
    text-align: center;
}

.footerEndereco {
    flex: 1;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    background-color: #111;

}

.footerInfoEndereco {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: small;
}


@media only screen and (max-width: 760px) {
    .footerContainer {
        font-size: smaller;
    }
    .footerContainerButtons {
        display: flex;
        flex-flow: column wrap;
    }
    .footerButton {
        padding: 10px 5px  ;
        max-width: 150px;
        cursor: pointer;
        border-radius: 4px;
        color: white;
    }

    .footerMenu, .footerServicos, .footerContato{
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
    }


}