.cidade {
  display: flex;
  flex: 0.5;
  justify-content: center;
  align-items: center;
}

.listaCidades {
  flex-direction: column;
  align-items: center;
  justify-items: center;
  border-left: 1px solid white;
  padding-left: 3.5%;
}

.cidadeBtn {
  font-size: 20px;
  display: flex;
  width: 100%;
  margin: 15px 0;
  padding: 2px 1px;
  color: #fff;
  cursor: pointer;
  align-items: center;
  font-size: 30px;
}
.cidadeBtn:hover {
  outline: 1px solid white;
  color: #da1f27;
  background-color: white;
  border-radius: 6px;
  box-sizing: border-box;
}

@media only screen and (max-width: 760px) {
  .listaCidades {
    border-left: 0px;
    border-top: 1px solid white;
    padding-left: 0;
    padding-top: 10px;
  }
  .cidadeBtn {
    font-size: 20px;
    display: flex;
    width: 100%;
    margin: 15px 0;
    padding: 2px 1px;
    color: #fff;
    cursor: pointer;
    align-items: center;
    font-size: 25px;
  }
}
