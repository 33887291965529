#planosContainer {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 30px 0;
    z-index: 0;
}

.planosMsg {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(100deg, #090086, #3967ff);
    padding: 20px;
    margin: 20px 15px;
    font-weight: bold;
    text-align: center;
    border-radius: 10px;
    color: white;
    font-size: large;
    line-height: 1.5;
    width: 90%;
}

#cardsContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 96vw;
    height: 540px;

}

#cardPlano {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 260px;
    z-index: 0;
}

#slidePlano
{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 500px;
}

.togglePlanos {
    background-color: #1976d2;
    color: white;
    box-shadow: 3px 3px 3px gray;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    cursor: pointer;
    user-select: none;
}

.togglePlanos:active { 
    background-color: blue
}

.cardItem {
    display: flex;
    flex-flow: column nowrap;
    margin: 4px 0
}
.cardTitle {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-weight: bold;
}
.cardSubtitle {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 4px 0
}
.cardLogo {
    margin: 0 6px 0 0;
    opacity: 30%;
    color: blue;
}
.cardSpan {
    font-size: 0.9rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin: 0 0 0 6px;
}


#planosContent {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

#cardContent {
    display: flex;
    flex-flow: column nowrap;
}

#planosBandwdth {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 100%;
    color: white;
    background: #3a79cc;
    font-size: 2.6rem;
    font-weight: bold;
}

#spanPrice {
    font-size: 1.5rem;
    font-weight: bold;
    color: #3a79cc;
    margin: 0 0 0 6px;
}

#promoIttvContainer {
    width: 100vw;
}

@media only screen and (max-width: 540px) {
    .cardsContainer {
        flex-flow: column wrap;
    }
}

@media only screen and (max-width: 760px) {
    .cardsContainer {
        flex-flow: row wrap;
    }
}

@media only screen and (min-width: 1800px) {
    #cardsContainer .swiper-wrapper {
        display: flex;
        justify-content: space-evenly;
    }
    #planosContainer {
        height: 800px;
    }
    #cardsContainer {
        height: 700px;
    }
    #cardPlano {
        width: 300px;
        height: 600px;
    }
    #slidePlano {
        height: 700px;
    }
    
}