.whatsappButton {
    position: fixed;
    margin: 0px;
    top: auto;
    right: 20px;
    bottom: 20px;
    left: auto;
    z-index: 1000;
}

.whatsappButton.barraVisible {
    animation: slideup 600ms forwards;
}

.whatsappButton.barraHidden{
    animation: slidedown 600ms forwards;
}

@media only screen and (max-width: 760) {
    .whatsappButton {
        bottom: 120px;
    }
}

@keyframes slideup {
    0% {
        bottom: 20px;
    }
    100% {
        bottom: 120px;
    }
}

@keyframes slidedown{
    0% {
        bottom: 120px;
    }
    100% {
        bottom: 20px;
    }
}