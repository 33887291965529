#canaisContainer {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    min-height: 70vh;

}

#canaisContent {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 70px 0 0 0;
    width: 100%;
    background: #eee;
    min-height: 80vh;
}

#canaisHeader {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 180px;
}

#listaCanaisContainer {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 0 40px 0;
}

.listaCanaisPlano {
    display: flex;
    flex-flow: column nowrap;
    justify-content: start;
    align-items: center;
    text-align: center;
    gap: 20px;
    width: 90%;
}

.listaCanaisTitulo {
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0 10px;
    color: #333;
    width: 33%;
}

.listaCanaisSubtitulo {
    font-size: 0.9rem;
    font-weight: 600;
    color: #aaa;

}

.MuiAccordionSummary-content {
    justify-content: space-around;
    max-width: 90%;
}

.categoriaCanaisContainer {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    width: 33.33%;
    min-height: 110px;
    border: 1px solid #eee;
    text-align: center;

}

.categoriasCanaisContent {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
}

.categoriaCanaisTitulo {
    font-size: 1rem;
    font-weight: 600;
    padding: 10px 0;
    color: #555;
    width: 100%;
}

.categoriaCanaisDescription {
    font-size: .8rem;
    font-weight: 300;
    padding: 10px 0;
    color: #888;
    width: 100%;
}

.imgCanal {
    padding: 5px;
    width: 50px;
    height: 50px;
    object-fit: scale-down;
}

.Abertos {
    width: 100%;
}



@media screen and (max-width: 768px) {

    .categoriaCanaisContainer {
        width: 50%;
    }

    .Abertos {
        width: 100%;
    }
}