.checkoutContainer {
    display: flex;
    flex-flow: row nowrap;
    background-color: azure;
    margin: 70px 0 0 0;
}

.formCheckout {
    display: flex;
    flex-flow: column nowrap;
    flex: 2;
    margin: 20px;
    justify-content: center;
}

.inputFormCheckout {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 20px 0 0 0 ;
}

.enderecoCheckout {
    display: flex;
    flex-flow: row nowrap;
}

.cardCheckout {
    justify-content: center;
    display: flex;
    flex: 1;
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
}

@media only screen and (max-width: 760px) {
    .checkoutContainer {
        flex-flow: column-reverse nowrap;
    }
}