.bannerContainer{
    background-color: white;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

}

.bannerApp {
    display: flex;
    flex: 1;
    max-height: 300px;
}

.imgBanner{
    width:100%;
    height:100%;
    object-fit: scale-down;
}

.botoesApp {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 25px ;
}

.googlePlayButtonContainer {
    margin: 15px;
}

.googlePlayButton {
    width: 100%;
    min-width: 100px;
    max-width: 150px;
    cursor: pointer;
}

.appStoreButtonContainer {
    margin: 15px;
}

.appStoreButton {
    width: 100%;
    min-width: 100px;
    max-width: 150px;
    cursor: pointer;
}

.areaDoClienteButtonContainer {
    margin: 15px;
}

.areaDoClienteButton {
    background-color: #0b00aa;
    max-width: 180px;
    padding: 10px;
    border-radius: 4px;
    color: black;
    box-shadow: 3px 3px 3px black;
}
.areaDoClienteLabel {
    color: white;
}