#funcionarioContainer{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;

}

#funcionarioTitle {
    margin: 10px 0 0 0
}


#funcionarioContent {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 70px 0 0 0;
    width: 100%;
    background: #eee;
}

#promoIttvContainer {
    width: 100%;
}

#spanPrice {
    font-size: 20px;
    font-weight: bold;
    color: #FF0000;
}