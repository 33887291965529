.servicosContainer {
    display: flex;
    flex-flow: row nowrap; 
    overflow: hidden;
    padding: 10px 0 0 0;
    background: #0044ff ;
}

.servicosContainerMobile {
    display: flex;
    flex-flow: column nowrap; 
    padding: 10px 0 0 0;
    background: #0044ff ;
}

.containerServico {
    display: flex;
    flex: 1;
}

.servicoimg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
}

.servicoimg.servicoimgMobile {
    width: 100%;
    width: 100vw;
    object-fit: contain;
}

.btnStoreContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
}


@media only screen and (min-width: 760px) {
        
    .servicoimg:hover {
        filter: brightness(80%);
        transition: all 200ms ease-in;
    }

    .servicoimg:not(:hover) {
        filter: brightness(100%);
        transition: all 400ms ease-out;
    }
}