* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(100deg, #003399, #008acd);
  height: 100%;
}

li {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: rgb(0, 0, 0);
  font-size: medium;
  background-color: blue;
}

select {
  font-size: large;
  color: black;
  background-color: blue;
}

.containerCidades {
  display: flex;
  height: 100vh;
}

.escolhaCidade {
  color: #fff;
  display: flex;
  flex: 0.5;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: bold;
  flex-direction: column;
}

.logoContainer {
  display: flex;
  flex: 3;
  justify-content: center;
  align-items: center;
}

#logoItnet {
  max-height: 400px;
  object-fit: cover;
}

.spanCidade {
  flex: 1;
  font-size: 30pt;
}

@media only screen and (max-width: 760px) {
  .containerCidades {
    flex-flow: column nowrap;
  }
  .escolhaCidade {
    flex: 0.4;
    padding: 10px 0;
  }
  #logoItnet {
    height: 100px;
    object-fit: cover;
  }
  .spanCidade {
    font-size: 20pt;
  }
}

@media only screen and (max-width: 540px) {
  .containerCidades {
    flex-flow: column nowrap;
  }
  .escolhaCidade {
    flex: 0.4;
    padding: 5px 0;
  }
  #logoItnet {
    height: 100px;
    object-fit: cover;
  }
  .spanCidade {
    font-size: 20pt;
  }
}
