.carouselContainer{
    display: flex;


}
.slideContainer {
    display: flex;
    position: relative;
    justify-content: center;
}

.imgCarousel{
    flex:1;
    width: 300px;
    object-fit: cover;
    user-select: none;
}


.carouselButton {
    position: absolute;
    border-radius: 4px;
    background-color: white;
    border: 2px solid white;
    color: black;
    user-select: none;
}

.carouselButton:hover {
    background-color: #2d68ff;
    border: 2px solid #2d68ff;
    color: white;
    transition: all 200ms ease-in-out;
    transition-delay: 50ms;
}

.carouselButton:not(:hover) {
    background-color: white;
    color: black;
    transition: all 200ms ease-in-out, color 200ms ease-in-out;
    transition-delay: 50ms;
}

.carouselButton:active {
    border: 2px solid white;
    transition: all 50ms ease-in-out;
    transition-delay: 20ms;
}

.carouselButton.buttonDesktop {
    bottom: 4%;
    left: 11%;
    padding: 1.2% 1.1%;
}

.carouselButton.buttonMobile {
    bottom: 2%;    
    padding: 20px;

}