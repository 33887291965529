.fundoHeader {
    background: linear-gradient(0deg, #0000, #0000); /*transparent;*/
    display: flex;
    position: fixed;
    flex-direction: row;
    height: 70px;
    width: 100%;
    top: 0;
    transition: background 200ms ease-in-out;
    z-index: 999;
}

.fundoHeader.transparent{
  background: linear-gradient(180deg, #0007, #0000); /*transparent;*/
}

.fundoHeader.solidheader {
    background: linear-gradient(100deg, #090086, #3967ff);/* #3967ff;*/ 
    transition: all 200ms ease-in-out;
}


.logoSite {
    display: flex;
    width: 100px;
    height: 50px;
    cursor: pointer;
}


.logoContainer {
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 10px;
}

.headerButtonsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 50px;
    color: white;

}

.headerButton {
    padding: 8px;
    margin: 8px 10px;
    font-weight: bold;
    cursor: pointer;
    font-size: 10pt;
    border-radius: 4px;
    color: white;
    text-align: center;
}

.headerButton.buttonOutlined {
    border: 1px solid white;
    font-size: 10pt;
    min-width: none;
}

.headerButton:hover {
    background-color: #fff5;
    transition: all 300ms ease-out;
}
.headerButton:not(:hover) {
    background-color: #0000;
    transition: all 300ms ease-in;
}

.divLogo {
    display: flex;
    align-items: center;
    flex: 1;
    margin: 5px 30px;
    border-radius: 4px;
}

.divLogo:hover {
    background-color: #fff4;
    transition: all 300ms ease-out;
}
.divLogo:not(:hover) {
    background-color: #0000;
    transition: all 300ms ease-in;
}


.divSpacer {
    flex: 9;
}



.divSelect {
    display: none;
    flex: 1;
}

.h1cidade{
    color: black;
    font-size: 15px;
}

.menuContainer {
    margin: 0 15px;
    display: flex;
    justify-items: center;
    align-items: center;
}

.WhatsappButton{
    position: absolute;
}

.MuiToggleButtonGroup-root {
    border: 1px solid white;
}

.MuiBox-root {
    color: white;
}



